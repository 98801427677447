<template>
  <div
    class="py-25 navbar-container d-flex justify-content-between content align-items-center px-0 flexbox-shrink-fix"
  >
    <div class="navbar-nav flex-grow-1 d-flex flexbox-shrink-fix flex-nowrap">
      <!-- Toggle Vertical menu -->
      <b-link
        v-if="hasMenu"
        class="nav-link menu d-flex align-items-center pl-0"
        @click="toggleVerticalMenuActive"
      >
        <feather-icon icon="MenuIcon" size="21" />
      </b-link>

      <!-- Space -->
      <template v-if="isCommunity">
        <CollectiveBrand :has-menu="hasMenu" />
      </template>

      <!-- My Nectios -->
      <template v-else>
        <b-link :to="{ name: 'Nectios' }" class="d-flex">
          <b-img :src="nectiosLogo" class="avatar ml-1" />
          <div class="ml-50 d-more-than-lg mt-75">
            <p class="font-weight-extrabold text-dark">
              {{ myNectiosTitle }}
            </p>
          </div>
        </b-link>
      </template>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto settings justify-content-end">
      <!-- ############## STAGING / LOCAL label ############## -->
      <span v-if="isStg == 'staging'" class="mr-1 etiquetaSTG">
         Staging 
      </span>
      <span v-if="isStg == 'development' || isStg == 'local'" class="mr-1 etiquetaLocal">
        Local
      </span>

      <!-- Calendar button -->
      <!--b-button
        v-if="isCommunity && !hasNotMenu && loggedMember"
        v-b-tooltip.hover
        :title="$t('header.action.calendar')"
        variant="link"
        class="p-0 mr-1 d-none d-lg-block"
        :to="calendarRoute"
      >
        <feather-icon icon="CalendarIcon" size="21" class="text-body" />
      </b-button-->

      <!-- ############## Notifications button ############## -->
      <NotificationsLink
        v-if="isCommunity && !hasNotMenu && loggedMember"
        :notifications-count="unreadNotificationsCount"
        :notifications-list="notificationsList"
        class="d-none d-lg-block p-0 mr-1"
      />

      <!-- Apps button -->
      <!-- TODO: fix BE endpoint -->
      <!--b-button
        v-if="!isCommunity"
        v-b-tooltip.hover
        title="App Store"
        variant="link"
        class="p-0 mr-1 d-none d-lg-block"
        :to="appsRoute"
      >
        <feather-icon icon="GridIcon" size="21" class="text-body" />
      </b-button-->

      <!-- ############## Resources and Help button ############## -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <b-button
            v-b-tooltip.hover
            :title="$t('help')"
            variant="link"
            class="p-0 mr-1 d-none d-lg-block"
          >
            <b-icon-question-circle scale="1.2" class="text-body" />
          </b-button>
        </template>

        <!-- RESOURCES -->
        <template>
          <b-dropdown-text class="bg-light">
            <p class="user-name font-weight-bolder mb-0 mr-25">
              {{ $t("resources") }}
            </p>
          </b-dropdown-text>
          <!-- Nectios Help Center -->
          <!--b-dropdown-item
            link-class="d-flex align-items-center"
            target="_blank"
            @click="accessHelpCenter"
          >
            <b-icon-question-circle class="mr-50" />
            <span>{{ $t("backoffice.dashboard.help-center") }}</span>
          </!b-dropdown-item-->
          <!-- Legal Center -->
          <b-dropdown-item
            link-class="d-flex align-items-center"
            target="_blank"
            :href="getLegalCenter"
          >
            <feather-icon size="16" icon="ShieldIcon" class="mr-50" />
            <span> {{ $t("backoffice.legal.center") }}</span>
          </b-dropdown-item>
          <!-- Space Information -->
          <b-dropdown-item
            v-if="isCommunity"
            link-class="d-flex align-items-center"
            @click="
              mainCommunity.slug
                ? getDetails(mainCommunity)
                : getDetails(currentCollective)
            "
          >
            <feather-icon size="16" icon="InfoIcon" class="mr-50" />
            <span>{{ $t("user-menu.information") }}</span>
          </b-dropdown-item>
          <!-- Contact with space admins -->
          <b-dropdown-item
            v-if="isCommunity"
            v-b-modal.feedback-modal
            link-class="d-flex align-items-center"
          >
            <b-icon-mailbox class="mr-50" />
            <span> {{ $t("backoffice.feedback.create-suggestion") }}</span>
          </b-dropdown-item>
          <!-- About Nectios software -->
          <b-dropdown-item
            v-if="!isCommunity"
            link-class="d-flex align-items-center"
            @click="handleSoftwareModal()"
          >
            <feather-icon size="16" icon="InfoIcon" class="mr-50" />
            <span>{{ $t("about-nectios.title") }}</span>
          </b-dropdown-item>
        </template>
        <!--
        <template v-if="isCommunity">
          <b-dropdown-text class="bg-light">
            <p class="user-name font-weight-bolder mb-0 mr-25">
              {{ $t('platform') }}
            </p>
          </b-dropdown-text>
        </template>
      -->
        <!--b-dropdown-item
          v-if="isCommunity"
          class="mb-0"
          target="_blank"
          :href="home"
        >
          <span
            v-b-tooltip.hover
            class="d-flex align-items-center icon-nectios"
            :title="$t('tooltips.nectios-home')"
          >
            <img :src="nectiosIcon" class="mr-50" />
            <span class="icon-text">My Nectios</span>
          </span>
        </b-dropdown-item-->
      </b-nav-item-dropdown>

      <!-- ############## USER / MEMBER dropdown menu ############## -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <!-- Desktop -->
          <UserAvatar
            :user="userOrMember"
            size="40"
            variant="light-primary"
            class="d-none d-lg-inline-flex badge-minimal"
            badge-variant="warning"
            :badge="isFirstConnection"
          />
          <!-- Mobile -->
          <UserAvatar
            :user="userOrMember"
            size="40"
            variant="light-primary"
            :badge-variant="isFirstConnection ? 'warning' : 'danger'"
            class="badge-minimal d-lg-none"
            :badge="unreadNotificationsCount > 0 || isFirstConnection"
          />
        </template>

        <!-- Profile -->
        <b-dropdown-text class="bg-light">
          <b-link
            :disabled="hasNotMenu || !loggedMember"
            :href="!hasNotMenu ? myProfileLocation() : ''"
            class="text-dark"
          >
            <p class="user-name font-weight-bolder mb-0">
              {{ profileFullName }}
              <span class="font-weight-normal">
                {{ profileEmail }}
              </span>
            </p>
          </b-link>
        </b-dropdown-text>

        <template v-if="isCommunity">
          <!-- My Account -->
          <b-dropdown-item 
            v-if="!hasNotMenu && loggedMember" 
            class="mb-0" 
            :to="{ name: 'globalMemberSettings' }"
          >
            <span class="d-flex align-items-center">
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>{{ $t("user-menu.profile-settings") }}</span>
            </span>
            <span v-if="isFirstConnection" class="d-flex align-items-center text-warning">
              <feather-icon icon="AlertCircleIcon" class="mr-25" />
              <span>{{ $t("missing-information") }}</span>
            </span>
          </b-dropdown-item>
          <!-- My organizations -->
          <!-- v-if="enabledOrganizationApp" -->
          <!--       <b-dropdown-item class="mb-0" @click="organizationsTeleport = true">
            <span class="d-flex align-items-center">
              <b-icon-building class="mr-50" />
              <span> {{ $t('organizations.my-organizations') }}</span>
            </span>
          </b-dropdown-item> -->
          <!--           <b-dropdown-item link-class="d-flex align-items-center" class="d-lg-none" :to="notificationsLocation">
            <feather-icon
              :badge="unreadNotificationsCount"
              badge-classes="bg-danger"
              class="mr-50"
              icon="BellIcon"
              size="16"
            />
            <span> {{ $t('notifications.title') }}</span>
          </b-dropdown-item> -->
            
          <!-- My Public Profile -->
          <b-dropdown-item 
            v-if="isMembersAppEnabled && ((hasMainCommunity && !currentCollective) || (isCommunityMember && !hasMainCommunity))" 
            class="mb-0" 
            :href="myProfileLocation()"
          >
            <span class="d-flex align-items-center">
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>{{ $t("user-menu.public-profile") }}</span>
            </span>
          </b-dropdown-item>

          <!-- Settings / Language -->
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="openSettings"
          >
            <!-- <feather-icon size="16" icon="CheckCircleIcon" class="mr-50" /> -->
            <!-- <font-awesome-icon :icon="['fas', 'language']" size="16" class="mr-50" /> -->
            <img :src="flagImage(activeLocale)" width="16" class="mr-50" />
            <span>{{ $t("user-menu.language") }}</span>
            <!-- <img :src="activeLocale.img" width="20" class="ml-50" /> -->
            <!-- <font-awesome-icon :icon="['fas', 'language']" size="30" class="ml-50" style="height: 30px; width: 30px;" /> -->
          </b-dropdown-item>

          <!-- My Calendar -->
          <b-dropdown-item
            v-if="loggedMember"
            link-class="d-flex align-items-center"
            class="d-lg-none"
            :to="calendarRoute"
          >
            <feather-icon size="16" icon="CalendarIcon" class="mr-50" />
            <span> {{ $t("header.action.calendar") }}</span>
          </b-dropdown-item>

          <!-- Main Space Section -->
          <template v-if="hasMainCommunity">
            <!-- Main Space Info -->
            <b-dropdown-text class="bg-light">
              <p class="user-name font-weight-bolder mb-0 mr-25">
                {{ translate(mainCommunity.name) }}
              </p>
              <!-- Staff / Member -->
              <span
                v-if="mainCommunity.accessibility !== 3"
                class="d-flex align-items-center"
              >
                <span
                  v-if="mainCollective.isStaff"
                  class="d-flex align-items-center"
                >
                  <b-icon-shield-check
                    v-b-tooltip.hover.bottom
                    size="1"
                    :title="$t('user-menu.staff-tooltip')"
                  />
                  <p class="user-name mb-0 mr-25 ml-25">
                    {{ $t("user-menu.staff") }}
                  </p>
                </span>
                <span
                  v-if="mainCollective.isStaff && isMainCollectiveMember"
                  class="mx-25"
                >
                  {{ $t("user-menu.&") }}
                </span>
                <span
                  v-if="isMainCollectiveMember"
                  class="d-flex align-items-center"
                >
                  <b-icon-person
                    v-b-tooltip.hover.bottom
                    size="1"
                    :title="$t('user-menu.member-tooltip')"
                  />
                  <p class="user-name mb-0 mr-25 ml-25">
                    {{ $t("user-menu.member") }}
                  </p>
                </span>
              </span>
            </b-dropdown-text>

            <!-- Main Backoffice -->
            <b-dropdown-item
              v-if="mainCollective.isStaff"
              link-class="d-flex align-items-center"
              target="_blank"
              :href="backofficeURL(mainCommunity, true)"
            >
              <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
              <span>{{ $t("user-menu.main-backoffice") }} </span>
            </b-dropdown-item>
            <!--             <b-dropdown-item
              v-if="isStaff && ($route.params.communitySlug !== mainCommunity.slug || $route.name !== 'welcome')"
              link-class="d-flex align-items-center"
              :href="getCollectiveUrl(mainCommunity)"
            >
              <b-icon-door-open class="mr-50" />
              <span>{{ $t('header.action.welcome') }}</span>
            </b-dropdown-item> -->
          </template>

          <!-- Current Space Section -->
          <b-dropdown-text class="bg-light">
            <p class="user-name font-weight-bolder mb-0 sub-name">
              {{ translate(currentCollective.name) }}
            </p>
            <!-- Staff / Member -->
            <span
              v-if="currentCollective.accessibility !== 3"
              class="d-flex align-items-center"
            >
              <span v-if="isStaffOfThis" class="d-flex align-items-center">
                <b-icon-shield-check
                  v-b-tooltip.hover.bottom
                  size="1"
                  :title="$t('user-menu.staff-tooltip')"
                />
                <p class="user-name mb-0 mr-25 ml-25">
                  {{ $t("user-menu.staff") }}
                </p>
              </span>
              <span
              v-if="isStaffOfThis && isCommunityMember" class="mx-25">
                {{ $t("user-menu.&") }}
              </span>
              <span
                v-if="isCommunityMember" class="d-flex align-items-center">
                <b-icon-person
                  v-b-tooltip.hover.bottom
                  size="1"
                  :title="$t('user-menu.member-tooltip')"
                />
                <p class="user-name mb-0 mr-25 ml-25">
                  {{ $t("user-menu.member") }}
                </p>
              </span>
            </span>
          </b-dropdown-text>

          <!-- Details -->
          <!--
          <b-dropdown-item v-if="!currentCollective.parentKey" link-class="d-flex align-items-center" @click="getDetails(currentCollective)">
            <feather-icon size="16" icon="InfoIcon" class="mr-50" />
            <span>{{ $t('user-menu.information') }}</span>
          </b-dropdown-item> -->

          <!-- Edit layout -->
          <b-dropdown-item
            v-if="canEditLayout && !isEditingLayout"
            link-class="d-flex align-items-center"
            target="_blank"
            @click="handleEditLayout"
          >
            <div v-b-tooltip.hover :title="$t('tooltips.edit-layout')">
              <feather-icon size="16" icon="Edit2Icon" class="mr-50" />
              <span>{{ $t("header.action.edit-layout") }}</span>
            </div>
          </b-dropdown-item>
          <!-- Backoffice -->
          <b-dropdown-item
            v-if="isStaff"
            target="_blank"
            link-class="d-flex align-items-center"
            :href="backofficeURL(mainCommunity)"
          >
            <div v-b-tooltip.hover :title="$t('tooltips.backoffice')">
              <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
              <span>Back-Office</span>
            </div>
          </b-dropdown-item>
          <!-- Welcome -->
          <b-dropdown-item
            v-if="$route.name !== 'welcome'"
            link-class="d-flex align-items-center"
            :href="getCollectiveUrl(collective)"
          >
            <b-icon-door-open class="mr-50" />
            <span>{{ $t('header.action.welcome') }}</span>
          </b-dropdown-item>

          <!--b-dropdown-item
            v-if="hasOldWorkspace"
            link-class="d-flex align-items-center"
            target="_blank"
            @click="accessToV1"
          >
            <feather-icon size="16" icon="ServerIcon" class="mr-50" />
            <span> {{ $t("access-workspace") }}</span>
          </b-dropdown-item-->
        </template>

        <!-- MY NECTIOS MENU -->
        <template v-else>
          <!-- <b-dropdown-item v-if="!hasNotMenu" class="mb-0" :href="myselfURL()"> -->
          <!-- MyNectios Account -->
          <b-dropdown-item 
            v-if="!hasNotMenu && loggedUser" 
            class="mb-0" 
            :to="{ name: 'my-settings' }"
          >
            <span class="d-flex align-items-center">
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>{{ $t("user-menu.profile-settings") }}</span>
            </span>
          </b-dropdown-item>

          <!-- MyNectios Subscription -->
          <b-dropdown-item
            v-if="isAppSumo"
            class="mb-0"
            :href="mySubscription"
          >
            <span class="d-flex align-items-center">
              <feather-icon size="16" icon="ListIcon" class="mr-50" />
              <span>{{ this.$t("appsumo.my-subscription.title") }}</span>
            </span>
          </b-dropdown-item>
        </template>

        <!-- Logout -->
        <b-dropdown-item
          link-class="d-flex align-items-center bg-light-info"
          @click="logOut()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>{{ $t("user-menu.logout") }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>

      <teleport to="body">
        <AppCustomizer
          :visible="settingsMenu"
          @change="handleCustomizerChange"
        />
      </teleport>

      <!-- Group Chat -->
      <teleport to="body">
        <ChatSidebar
          v-if="isChatRoomVisible"
          #default="{ hide }"
          v-model="isChatRoomVisible"
          @shown="$refs.chatRoom.scrollToBottom()"
        >
          <BasicCollectiveChatRoomWidget
            ref="chatRoom"
            stretch
            autofocus
            :title="translate(collective.name) || collective.title"
            show-avatar
            sidebar
            class="mb-0 rounded-0"
          >
            <template #header>
              <b-button-close @click="hide">
                <b-icon-x />
              </b-button-close>
            </template>
          </BasicCollectiveChatRoomWidget>
        </ChatSidebar>
      </teleport>
      
      <!-- My Organizations -->
      <teleport to="body">
        <b-sidebar
          v-model="organizationsTeleport"
          right
          no-header
          backdrop
          width="400px"
          body-class="overflow-hidden"
          @shown="getLoggedMember"
        >
          <!-- Header -->
          <div
            class="pt-2 px-2 d-flex justify-content-between align-items-center"
          >
            <div>
              <h4 class="mb-0">
                {{ $t("organizations.my-organizations") }}
              </h4>
            </div>
            <feather-icon
              icon="XIcon"
              size="18"
              class="cursor-pointer"
              @click="organizationsTeleport = false"
            />
          </div>
          <hr />
          <div
            v-if="
              !areOrganizationsLoading &&
              member &&
              member.organizations &&
              member.organizations.length > 0
            "
          >
            <p
              v-for="(organization, index) in member.organizations"
              :key="index"
              class="px-2"
            >
              <b-link @click="organizationLocation(organization)">
                {{ translate(organization.name) }}
              </b-link>
            </p>
          </div>
          <b-spinner
            v-else-if="areOrganizationsLoading"
            type="grow"
            small
            class="mx-auto d-block text-primary"
          />
          <div v-else class="horizontal-placeholder flex-column pb-5">
            <div class="pb-5">
              <img :src="placeholder" class="m-0 d-block mx-auto" />
              <p class="text-primary">
                {{
                  $t("backoffice.feedback.placeholder-text", {
                    item: $t("organizations.title"),
                  })
                }}
              </p>
            </div>
          </div>
        </b-sidebar>
      </teleport>
    </b-navbar-nav>

    <!-- Contact / Feedback / suggestions -->
    <b-modal
      id="feedback-modal"
      :title="$t('backoffice.feedback.modal-title')"
      :ok-title="$t('form.actions.send')"
      size="lg"
      ok-only
      @hidden="isValid = null"
      @ok="handleSuggestion()"
    >
      <b-form-group class="flex-grow-1">
        <b-form-group class="flex-grow-1">
          <label for="suggestionType" class="font-weight-bold">
            {{ $t("backoffice.feedback.type") }}</label
          >
          <v-select
            v-model="suggestion.type"
            name="suggestionType"
            label="suggestionType"
            :searchable="false"
            :clearable="false"
            :options="typeOptions"
          />
        </b-form-group>
        <label for="suggestion" class="font-weight-bold mt-1">
          {{ $t("backoffice.feedback.name") }}*</label
        >
        <b-form-textarea
          id="suggestion"
          v-model="suggestion.message"
          type="text"
          rows="6"
          :placeholder="$t('form.type-placeholder')"
        />
      </b-form-group>
    </b-modal>

    <!-- Space Details modal -->
    <b-modal
      v-model="isOpenedDetailsModal"
      :title="$t('user-menu.information')"
      size="lg"
      hide-footer
    >
      <b-container class="mt-75">
        <h3 class="mb-0">
          {{ translate(detailsCommunity.name) }}
        </h3>
        <p v-if="detailsCommunity.headline" class="text-muted mb-0">
          {{ translate(detailsCommunity.headline) }}
        </p>
        <b-badge
          pill
          variant="primary"
          class="text-capitalize align-self-start mt-50"
        >
          {{ $t(`collectives.items.${detailsCommunity.mainType}`) }}
        </b-badge>
        <hr />
        <div v-if="detailsCommunity.description">
          <h4>{{ $t("backoffice.types.description") }}</h4>
          <span
            class="html-text-ellipsis-3"
            v-html="translate(detailsCommunity.description)"
          />
          <hr />
        </div>
        <h5>{{ $t("about-nectios.contact-information") }}</h5>
        <hr v-if="!detailsCommunity.description" />
        <b-row v-if="hasContactInfo">
          <b-col>
            <!-- email -->
            <b-row
              v-if="
                detailsCommunity.emails && detailsCommunity.emails.length > 0
              "
              :class="{ 'mt-1': detailsCommunity.headline }"
            >
              <b-col cols="12" md="4" lg="3" class="font-weight-bold">
                {{ $t("organizations.information.emails") }}
              </b-col>
              <b-col cols="12" md="8" lg="9">
                <div
                  v-for="(email, index) in detailsCommunity.emails"
                  :key="index"
                >
                  <small class="text-muted mb-0">{{ email.label }}</small>
                  <p>
                    <a
                      :class="detailsCommunity.emails.length > 1 ? 'mb-25' : ''"
                      target="_blank"
                      :href="`mailto:${email.email}`"
                      >{{ email.email }}</a
                    >
                  </p>
                </div>
              </b-col>
            </b-row>
            <!-- Locations -->
            <b-row
              v-if="
                detailsCommunity.locations &&
                detailsCommunity.locations.length > 0
              "
              class="pt-1"
            >
              <b-col cols="12" md="4" lg="3" class="font-weight-bold">
                {{ $t("backoffice.settings.subtitle-locations") }}
              </b-col>
              <b-col cols="12" md="8" lg="9">
                <p
                  v-for="(location, index) in detailsCommunity.locations"
                  :key="index"
                >
                  {{ location.address }}
                </p>
              </b-col>
            </b-row>
            <!-- Phone -->
            <b-row
              v-if="
                detailsCommunity.phones && detailsCommunity.phones.length > 0
              "
              class="pt-1"
            >
              <b-col cols="12" md="4" lg="3" class="font-weight-bold">
                {{ $t("backoffice.settings.subtitle-phones") }}
              </b-col>
              <b-col cols="12" md="8" lg="9" class="d-flex flex-column">
                <div
                  v-for="(phone, index) in detailsCommunity.phones"
                  :key="index"
                >
                  <small class="text-muted mb-0">{{ phone.label }}</small>
                  <p :class="detailsCommunity.phones.length > 1 ? 'mb-25' : ''">
                    <a :href="`tel:+${phone.country.phoneCode}${phone.number}`"
                      >+{{ phone.country.phoneCode }} {{ phone.number }}</a
                    >
                  </p>
                </div>
              </b-col>
            </b-row>
            <!-- Website -->
            <b-row v-if="detailsCommunity.landing" class="pt-1">
              <b-col cols="12" md="4" lg="3" class="font-weight-bold">
                {{ $t("header.action.landing") }}
              </b-col>
              <b-col cols="12" md="8" lg="9">
                <a :href="collectiveLanding" target="blank_">
                  {{ truncatedLink }}
                </a>
              </b-col>
            </b-row>
            <!-- Links -->
            <b-row
              v-if="detailsCommunity.links && detailsCommunity.links.length > 0"
              class="pt-1"
            >
              <b-col cols="12" md="4" lg="3" class="font-weight-bold">
                {{ $t("header.action.social-media") }}
              </b-col>
              <b-col cols="12" md="8" lg="9" class="d-flex">
                <p v-for="(link, index) in detailsCommunity.links" :key="index">
                  <a
                    :href="toAbsoluteURL(link.url)"
                    target="_blank"
                    class="mr-1"
                  >
                    <feather-icon
                      :icon="
                        link.label === 'Website'
                          ? 'GlobeIcon'
                          : `${link.label}Icon`
                      "
                      size="18"
                      class="social-icons"
                    />
                  </a>
                </p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row v-else class="d-block mx-auto">
          <p class="text-muted">
            {{ $t("members.no-about-message") }}
          </p>
        </b-row>
        <!-- <b-row v-if="canUnsuscribe && detailsCommunity.slug !== 'help-center'">
          <b-col>
            <feather-icon icon="XCircleIcon" size="16" class="text-body pr-25" />
            <b-button variant="link" class="pl-0" @click="isLeaveModalOpened = true">
              {{ $t('header.action.leave-community') }}
            </b-button>
          </b-col>
        </b-row> -->
        <hr class="mt-1 mb-50 pb-25" />
        <b-row class="mt-2 mb-50">
          <b-col cols="12" lg="6" class="small">
            © {{ $t("brand") }}
            {{ new Date().getFullYear() }}
          </b-col>
          <b-col cols="12" lg="6" class="text-right">
            <a
              :href="privacyPolicyCurrentCommunityURL(detailsCommunity)"
              target="_blank"
              class="mr-50 small"
            >
              {{ $t("header.action.privacy-policy") }}
            </a>
            |
            <a
              :href="termsConditionsCurrentCommunityURL(detailsCommunity)"
              target="_blank"
              class="ml-50 small"
            >
              {{ $t("about-nectios.terms-conditions") }}
            </a>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <!-- Leave/Unsubscribe Member from community -->
    <b-modal
      v-model="isLeaveModalOpened"
      :title="$t('unsubscribe-community.cancel-subscription')"
      size="lg"
      hide-footer
    >
      <feather-icon
        size="30"
        icon="AlertTriangleIcon"
        class="d-block mx-auto text-danger mb-1"
      />
      <h5 class="text-center mb-2">
        {{
          $t("unsubscribe-community.message", {
            communityName: translate(currentCollective.name),
          })
        }}
      </h5>
      <div class="d-flex justify-content-center mb-2 flex-wrap">
        <b-button
          variant="primary"
          class="mr-md-2 mb-1 mb-md-0"
          @click="isLeaveModalOpened = false"
        >
          {{ $t("unsubscribe-community.stay-subscribed") }}
        </b-button>
        <b-button
          v-if="loggedMember"
          variant="outline-danger"
          @click="unsubscribeCommunity()"
        >
          {{ $t("unsubscribe-community.cancel-subscription") }}
        </b-button>
      </div>
    </b-modal>

    <!-- Delete User from Nectios -->
    <DeleteUserModal />

    <!-- About Nectios -->
    <AboutNectiosModal />
  </div>
</template>

<script>
import { VBTooltip } from "bootstrap-vue";
import Teleport from "vue2-teleport";
import {
  ENABLED_APPS_GETTERS,
  ENABLED_APPS_MUTATIONS,
} from "@/store/enabled-apps/enabled-apps-store-constants";
import { NOTIFICATIONS_GETTERS, NOTIFICATIONS_MUTATIONS } from "@/store/notifications/notifications-store-constants";
import GridStatusMixin from "@core/mixins/apps-layout/GridStatusMixin";
import { toAbsoluteURL } from "@core/utils/url-utils";
import Vue from "vue";
import Service from "@/config/service-identifiers";
import vSelect from "vue-select";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import NectiosLogo from "@/assets/images/placeholders/light/Nectios_Logo.jpg";
//import CollectiveLogo from "@core/components/collective-logo/CollectiveLogo.vue";
import Placeholder from "@/assets/images/placeholders/light/general-placeholder.svg";
import NectiosIcon from "@/assets/images/placeholders/light/Nectios-icon.svg";
import { MainTypeTranslated } from "@copernicsw/community-common";
import { isDownSize } from "@/@core/utils/responsive-utils";
import { getCollectiveUrl } from "@core/utils/collective";
import {
  menuItems,
} from "@/backoffice/constants/backofficeMenu";
import { myMenuItems, myselfMenuItems } from "@/views/myself/components/myMenu";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";

import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
// import BasicCollectiveChatRoomWidget from "@/views/apps/chat/widgets/BasicCollectiveChatRoomWidget.vue";
// import ChatSidebar from "@/views/apps/chat/components/ChatSidebar.vue";
// import UserAvatar from "@core/components/user/UserAvatar.vue";
// import NotificationsLink from "./NotificationsLink.vue";
// import CollectiveBrand from "./CollectiveBrand.vue";
// import deleteUserModal from "./navbar-modals/deleteUser.vue";
// import aboutNectiosModal from "./navbar-modals/aboutNectios.vue";

export default {
  name: "Navbar",
  directives: {
    "b-tooltip": VBTooltip,
  },

  components: {
    Teleport,
    vSelect,
    AppCustomizer,
    // AppCustomizer: () => import("@core/layouts/components/app-customizer/AppCustomizer.vue" /* webpackChunkName: "AppCustomizer" */),

    BasicCollectiveChatRoomWidget: () => import("@/views/apps/chat/widgets/BasicCollectiveChatRoomWidget.vue" /* webpackChunkName: "BasicCollectiveChatRoomWidget" */),
    UserAvatar: () => import("@core/components/user/UserAvatar.vue" /* webpackChunkName: "UserAvatar" */),
    ChatSidebar: () => import("@/views/apps/chat/components/ChatSidebar.vue" /* webpackChunkName: "ChatSidebar" */),
    CollectiveBrand: () => import('./CollectiveBrand.vue' /* webpackChunkName: "CollectiveBrand" */),
    NotificationsLink: () => import('./NotificationsLink.vue' /* webpackChunkName: "NotificationsLink" */),
    DeleteUserModal: () => import('./navbar-modals/deleteUser.vue' /* webpackChunkName: "DeleteUserModal" */),
    AboutNectiosModal: () => import('./navbar-modals/aboutNectios.vue' /* webpackChunkName: "AboutNectiosModal" */)
  },

  mixins: [GridStatusMixin, ToastNotificationsMixin],
  mixins: [GridStatusMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isChatRoomVisible: false,
      isOnlyStaff: false,
      isOpenedStaffModal: false,
      isOpenedSoftwareModal: false,
      isOpenedDetailsModal: false,
      isMainCollectiveMember: false,
      isLeaveModalOpened: false,
      organizationsTeleport: false,
      areOrganizationsLoading: false,
      member: {},
      isFirstConnection: false, // Not implemented in BE
      stage: null,
      enabledOrganizationApp: false,
      detailsCommunity: [],
      activatedApps: [],
      suggestion: {
        message: "",
        type: "",
      },
      isSmallDevice: true,
      isMediumDevice: false,
      isLargeDevice: false,
      notificationsCount: null,
      notificationsList: null,
      localUnreadCount: 0,
    };
  },
  computed: {
    hasMenu() {
      return (
        (this.contentLayoutType === "vertical" || this.isMobile) &&
        (this.activatedApps.length > 0 ||
          this.hasBackofficeMenu ||
          this.hasMyselfMenu ||
          this.hasStructure)
      );
    },
    locale() {
      return this.$store.state.locale;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    locales() {
      return this.$store.getters.locales;
    },
    activeLocale() {
      const lang = this.locales.filter((language) => this.currentLocale === language.value);
      // console.log('lang', lang);
      return lang? lang[0] : null;
    },
    isMobile() {
      return window.innerWidth < 1000;
    },
    trasnlatedType() {
      const translatedType = this.detailsCommunity.mainType;
      const types = {
        Community: MainTypeTranslated[0],
        Event: MainTypeTranslated[1],
        Course: MainTypeTranslated[2],
        Project: MainTypeTranslated[3],
        CoordinationCenter: MainTypeTranslated[4],
        HelpCenter: MainTypeTranslated[5],
        Section: MainTypeTranslated[6],
        Subcommunity: MainTypeTranslated[7],
        Workspace: MainTypeTranslated[8],
        Organization: MainTypeTranslated[9],
      };
      return types[translatedType];
    },
    hasContactInfo() {
      return (
        this.detailsCommunity?.emails?.length > 0 ||
        this.detailsCommunity?.locations?.length > 0 ||
        this.detailsCommunity?.phones?.length > 0 ||
        this.detailsCommunity?.links?.length > 0
      );
    },
    canUnsuscribe() {
      return this.loggedMember?.username;
    },
    mySubscription() {
      const nectios = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        "{subdomain}",
        "app"
      )?.replace("{slug}", "my");
      return `${nectios}/subscription`;
    },
    nectiosIcon() {
      return NectiosIcon;
    },
    placeholder() {
      return Placeholder;
    },
    home() {
      const mainCommunity = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        "{subdomain}",
        "app"
      )?.replace("{slug}", "");
      return `${mainCommunity}`;
    },
    isStg() {
      if (process.env.VUE_APP_ENV !== "production") {
        this.stage = process.env.VUE_APP_ENV;
        return this.stage;
      }
      this.stage = null;
      return false;
    },
    /*   isMobile() {
      return this.isGridDisabled;
    }, */
    contentLayoutType() {
      if (this.isMobile) {
        return "vertical";
      }
      if (
        this.$route.path.includes("/backoffice/") ||
        this.$route.path.includes("/myself/")
      ) {
        return "vertical";
      }
      if (this.$store.getters.currentCollective.menu?.layout) {
        return this.$store.getters.currentCollective.menu.layout;
      }
      return this.$store.state.appConfig.layout.type;
    },
    memberLocation() {
      if (!this.loggedMember?.username) {
        return null;
      }
      return {
        name: "myDashboard",
        params: {
          username: this.loggedMember?.username,
          communityId: this.$route.params.communityId,
        },
      };
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMember() {
      const loggedMemberObject = this.$store.getters.loggedMember;
      if(!loggedMemberObject.username){
        return null;
      }
      return loggedMemberObject;
    },
    profileFullName() {
      return this.loggedMember 
      ? (this.loggedMember.name + ' ' + this.loggedMember.surname) 
      : (this.loggedUser.name + ' ' + this.loggedUser.surname) ;
    },
    profileName() {
      return this.loggedMember ? this.loggedMember.name : this.loggedUser.name;
    },
    profileSurname() {
      return this.loggedMember ? this.loggedMember.surname : this.loggedUser.surname;
    },
    profileEmail() {
      return this.loggedMember ? this.loggedMember.email : this.loggedUser.email;
    },
    profileAvatar() {
      return this.loggedMember ? this.loggedMember.avatarURL : this.loggedUser.avatarURL;
    },
    userOrMember() {
      if(this.loggedMember){
        return this.loggedMember;
      }
      return this.loggedUser;
    },
    isCommunityMember() {
      return this.loggedMember &&
        this.loggedMember.username &&
        this.loggedMember.memberOfThis &&
        this.loggedMember.status === 3;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    subcollective() {
      return this.$store.state.collective.subcollective;
    },
    collectiveName() {
      return this.$store.state.collective.collective.slug;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainCommunity() {
      return this.mainCollective == null
        ? this.currentCollective || this.$route.params.communitySlug
        : this.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
      // return this.currentCollective?.parentKey ? this.mainCollective : this.currentCollective;
    },
    hasMainCommunity() {
      return (
        Object.entries(this.mainCommunity).length !== 0 &&
        this.currentCollective !== this.mainCommunity
      );
    },
    settingsMenu() {
      return this.$store.state.uiStates.settingsMenu;
    },
    isHome() {
      return this.$route.name === "Resumen";
    },
    enabledApps() {
      return this.$store.getters[ENABLED_APPS_GETTERS.enabledApps];
    },
    apps(){
      return this.$store.getters.apps?.apps
    },
    enabledAppsKeys() {
      const installedApps = [];
      if(!this.apps){
        return installedApps;
      }
      Object.values(this.apps).forEach(item => {installedApps.push(item.nameSingular)});
      return installedApps;
    },
    isMembersAppEnabled() {
      return this.enabledAppsKeys?.filter((item) => item === 'member').length > 0;
    },
    membersApp() {
      return Object.values(this.enabledAppsKeys).find(({ id }) => id === 50);
      return Object.values(this.enabledApps).find(({ id }) => id === 50);
    },
    calendarRoute() {
      return {
        name: "calendar",
        params: { communityId: this.$route.params.communityId },
      };
    },
    appsRoute() {
      return {
        name: "apps",
        params: { },
      };
    },
    mySpacesLocation() {
      return {
        name: "my-communities",
      };
    },
    isStaff() {
      return this.currentCollective.isStaff || this.mainCollective?.isStaff;
    },
    isStaffOfThis() {
      return (
        this.currentCollective.isStaffOfThis || this.currentCollective.isStaff
      );
    },
    notificationsLocation() {
      return {
        name: "notifications",
        params: {
          communityId: this.$route.params.communityId,
        },
      };
    },
    canEditLayout() {
      return (
        this.isHome && this.currentCollective.isStaff && !this.isSmallDevice
      );
    },
    isEditingLayout() {
      return this.$store.getters[ENABLED_APPS_GETTERS.isEditingLayout];
    },
    collectiveLanding() {
      return this.currentCollective?.landing
        ? toAbsoluteURL(this.currentCollective?.landing)
        : null;
    },
    unreadNotificationsCount() {
      // return this.$store.getters[NOTIFICATIONS_GETTERS.unreadCount];
      return this.localUnreadCount;
    },
    typeOptions() {
      return [
        this.$t("backoffice.feedback.type-options.bug"),
        this.$t("backoffice.feedback.type-options.improvement"),
        this.$t("backoffice.feedback.type-options.query"),
      ];
    },
    hasNotMenu() {
      return (
        this.$route.name === "onboarding-form" ||
        this.$route.name === "onboarding-plans" ||
        this.$route.name === "onboarding-checkout" ||
        this.$route.name === "onboarding-success"
      );
    },
    nectiosLogo() {
      return NectiosLogo;
    },
    isCommunity() {
      // This information will not come out if we are in my-netios
      return (
        this.$route?.params?.communityId || this.$route?.params?.communitySlug
      );
    },
    myNectiosTitle() {
      return "My Nectios";
    },
    hasLayout() {
      const response = this.$store.getters.apps.apps;
      return response == null ? 0 : Object.keys(response).length;
    },
    truncatedLink() {
      if (this.isSmallDevice) {
        if (
          this.detailsCommunity &&
          this.detailsCommunity.landing.length > 23
        ) {
          return `${this.detailsCommunity.landing?.substring(0, 22)}...`;
        }
      } else if (this.isMediumDevice) {
        if (
          this.detailsCommunity &&
          this.detailsCommunity.landing.length > 34
        ) {
          return `${this.detailsCommunity.landing?.substring(0, 33)}...`;
        }
      }
      return this.detailsCommunity.landing;
    },
    hasBackofficeMenu() {
      return (
        menuItems.includes(this.$route.name) || this.$route.name === "records"
      );
    },
    hasMyMenu() {
      return myMenuItems.includes(this.$route.name);
    },
    hasMyselfMenu() {
      return myselfMenuItems.includes(this.$route.name);
    },
    hasStructure() {
      return (
        this.collective.communityMain &&
        this.collective.parentKey &&
        this.collective.communityMain !== this.collective.parentKey
      );
    },
    profileTitle() {
      return this.isCommunity
        ? this.$t("user-menu.profile-settings")
        : this.$t("settings.title");
    },
    isAppSumo() {
      return this.$store.getters.loggedUser?.appSumoID;
    },
    legalCenterNectiosURL() {
      return `my/legal-center?locale=${this.currentLocale}`;
    },
    getLegalCenter() {
      let link = this.legalCenterNectiosURL;
      if (this.isCommunity) {
        link = this.legalCenterCurrentCommunityURL(
          this.mainCommunity.slug ? this.mainCommunity : this.currentCollective
        );
      }
      return link;
    },
    getPublicAppStore() {
      return `apps`;
    },
    hasOldWorkspace() {
      //TODO: delete when BASE is erased
      const v1Workspaces = [
        '4b8619a5-4aef-4f21-8866-e3b3bd9ac8e5', //grupo sigma
        'a37e7be4-2783-4605-823c-12b4d308e503'  //nectios
      ];
      return v1Workspaces.includes(this.currentCollective.key);
    }
  },
  watch: {
    mainCollective(value) {
      //this.installedMainApps();
      return value;
    },
    isMobile(value) {
      return value;
    },
  },
  mounted(){
    this.$store.dispatch(NOTIFICATIONS_MUTATIONS.resetNotifications);
    this.fetchNotifications()
  },
  async created() {
    
    await this.getMainCommunityMember();
    if (this.$route?.params?.communityId) {
      this.fetchData();
      
    }
    this.suggestion.type = this.$t(
      "backoffice.feedback.type-options.improvement"
    );
    //await this.installedMainApps(); //??
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {

    async fetchNotifications(){
      // console.log('fetchNotifications');
      // console.log('currentCollective', this.$store.getters.currentCollective);
      // console.log('mainCollective', this.$store.getters.mainCollective);
      // console.log('mainSpace', this.mainSpace);
      try {
        const response = await this.$store.$service[Service.BackendClient].get('/notifications', {
          params: {
            mainSpaceKey: this.mainSpace.key,
            communityKey: this.currentCollective.key,
            isDescendentOrdered: true,
            page:1,
            count: 8,
          },
        });
        const {data} = response?.data;
        if (data?.length > 0) {
          const sortedNotificationWrtIsRead = data.filter(({ isRead }) => isRead === 0);
          setTimeout(() => {
            this.localUnreadCount = sortedNotificationWrtIsRead.length;
            this.$store.dispatch(NOTIFICATIONS_MUTATIONS.setUnreadCount, sortedNotificationWrtIsRead.length);
            this.$store.dispatch(NOTIFICATIONS_MUTATIONS.SET_NOTIFICATIONS, sortedNotificationWrtIsRead);
          }, 2000);

          this.notificationsList = sortedNotificationWrtIsRead;
        }
        
      } catch(error) {
        console.error('error occurred while getting notifications', error)
      }
    },
    
    translate(field) {
      return translateTranslationTable(this.currentLocale, field);
    },
    handleResize() {
      this.isSmallDevice = isDownSize("sm");
      this.isMediumDevice = isDownSize("md");
    },
    async fetchData() {
      const [, communitySlug] = window.location.pathname.split("/");
      const response = this.$store.getters.apps;
      this.activatedApps =
        response.apps != null
          ? Object.values(response.apps).filter((app) => app?.isInMenu)
          : [];
    },
    timestampToTime(timestamp) {
      return new Date(timestamp).toLocaleDateString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    },
    termsConditionsCurrentCommunityURL(detailsCommunity) {
      return `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
        "{subdomain}",
        detailsCommunity?.subdomain || "app"
      ).replace("{slug}", detailsCommunity.slug)}/terms_conditions?locale=${
        this.currentLocale
      }`;
    },
    privacyPolicyCurrentCommunityURL(detailsCommunity) {
      return `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
        "{subdomain}",
        detailsCommunity?.subdomain || "app"
      ).replace("{slug}", detailsCommunity.slug)}/privacy_policy?locale=${
        this.currentLocale
      }`;
    },
    legalCenterCurrentCommunityURL(detailsCommunity) {
      return `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
        "{subdomain}",
        detailsCommunity?.subdomain || "app"
      ).replace("{slug}", detailsCommunity.slug)}?locale=${this.currentLocale}`;
    },
    getDetails(community) {
      this.detailsCommunity = community;
      this.isOpenedDetailsModal = !this.isOpenedDetailsModal;
    },
    // getSpaceInformation(community) {
    //   this.detailsCommunity = community;
    //   this.isOpenedDetailsModal = !this.isOpenedDetailsModal;
    // },
    unsubscribeCommunity() {
      this.$store.dispatch("unsubscribeCommunity", {
        communityKey: this.currentCollective.key,
      });
      this.logOut();
    },
    organizationLocation(organization) {
      const main = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        "{subdomain}",
        this.mainCollective.subdomain ||
          this.currentCollective.subdomain ||
          "app"
      )?.replace(
        "{slug}",
        this.mainCollective?.slug || this.currentCollective.slug
      );
      if (this.mainCollective?.slug) {
        window.open(`${main}/organizations/${organization.slug}`, "_blank");
      } else {
        window.location.href = `${main}/organizations/${organization.slug}`;
      }
    },
    async getLoggedMember() {
      //await this.installedMainApps();
      this.areOrganizationsLoading = true;
      const response = await this.$store.$service[Service.BackendClient].get(
        "/communityMembers",
        {
          params: {
            communitySlug:
              this.mainCollective?.slug ||
              this.$store.getters.currentCollective.slug,
            username: this.loggedMember.username,
            isForProfile: true,
          },
        }
      );
      this.member = response.data.data[0];
      this.areOrganizationsLoading = false;
    },
    /*     async removeCommunity() {
      await this.$store.$service[Service.BackendClient].post("user/delete", {
        userKey: this.loggedUser.key,
      });
      this.$store.dispatch("logout");
      window.location.href = process.env.VUE_APP_LANDING_URL;
    }, */
    handleCustomizerChange(visible) {
      if (!visible) {
        this.$store.dispatch("closeSettingsMenu");
      } else {
        this.$store.dispatch("openSettingsMenu");
      }
    },
    openSettings() {
      //console.log('Opening language settings...');
      this.$store.dispatch("openSettingsMenu");
    },
    logOut() {
      console.log('Navbar.vue - dispatch logout');
      this.$store.dispatch("logout");
    },
    handleEditLayout() {
      this.$store.commit(ENABLED_APPS_MUTATIONS.toggleEditLayout);
    },
    handleSoftwareModal() {
      // this.isOpenedSoftwareModal = true;
      this.$bvModal.show("about-nectios-modal");
    },
    backofficeURL(item, main = false) {
      if (
        item == null &&
        (this.$route.params.communitySlug == null ||
          this.$route.params.communityId == null)
      ) {
        return "#";
      }
      if (main) {
        item = { slug: item.slug || item.id, subdomain: item?.subdomain };
      } else {
        item = {
          slug:
            this.$route.params.communitySlug || this.$route.params.communityId,
          subdomain: this.currentCollective?.subdomain,
        };
      }
      const mainCommunity = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        "{subdomain}",
        item.subdomain || "app"
      )?.replace("{slug}", item.slug);
      return `${mainCommunity}/backoffice/settings`;
    },
    myselfURL() {
      const item = {
        slug:
          this.$route.params.communitySlug || this.$route.params.communityId,
        subdomain: this.currentCollective?.subdomain,
      };
      if (this.isCommunity) {
        const mainCommunity =
          process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
            "{subdomain}",
            item.subdomain || "app"
          )?.replace("{slug}", item.slug);
        return `${mainCommunity}/myself/settings`;
      }
      return "/my/settings";
    },
    mynectiosURL() {
      return "/my/settings";
    },
    myProfileLocation() {
      const item = {
        slug:
          this.$route.params.communitySlug || this.$route.params.communityId,
        subdomain: this.currentCollective?.subdomain,
      };
      const community = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        "{subdomain}",
        item.subdomain || "app"
      )?.replace("{slug}", item.slug);
      const username = this.$store.getters.loggedMember?.username;
      return `${community}/members/${username}`;
    },
    async getMainCommunityMember() {
      this.isMainCollectiveMember = this.$store.getters.loggedMember;
    },
    policyURL(item) {
      return `${process.env.VUE_APP_LEGAL_CENTER_URL?.replace(
        "{subdomain}",
        item.subdomain || "app"
      )?.replace("{slug}", item.slug)}`;
    },
    getCollectiveUrl(collective) {
      /*       const urlParam = this.getParamUrl(this.collective);
       */ return getCollectiveUrl(collective, "/welcome");
    },
    /*     getParamUrl(collective) {
      const type = collective?.mainType?.toLowerCase();
      const slug = collective?.slug;
      if (type === 'event' || collective.isEvent) return `/events/${slug}`;
      if (type === 'section' || collective.isSection) return '/sections';
      if (type === 'course' || collective.isCourse) return '/courses';
      if (type === 'project') return `/projects/${slug}`;
      if (type === 'challenge') return `/challenges/${slug}`;
      if (type === 'subcommunity') return '/subcommunities';
      return '';
    }, */

    async handleSuggestion() {
      if (this.suggestion.message !== "") {
        try {
          const suggestionType =
            this.suggestion.type ===
            this.$t("backoffice.feedback.type-options.bug")
              ? "bug"
              : this.suggestion.type ===
                this.$t("backoffice.feedback.type-options.improvement")
              ? "feature_improvement"
              : "query";
          await this.$store.dispatch("createItem", {
            item: {
              itemType: "backoffice/feedback",
              customName: "feedback",
              requestConfig: {
                message: this.suggestion.message,
                type: suggestionType,
                status: "To do",
              },
            },
          });
          this.notifySuccess(this.$t("backoffice.feedback.messages.success"));
          this.suggestion.message = "";
          this.suggestion.type = this.$t(
            "backoffice.feedback.type-options.improvement"
          );
        } catch {
          this.notifyError(this.$t("backoffice.feedback.messages.error"));
        }
      } else {
        this.notifyError(this.$t("backoffice.feedback.messages.error"));
      }
      // await this.$store.dispatch('getFeedback');
    },
    // async installedMainApps() {
    //   const response = await this.$store.dispatch("mainApps");
    //   if (response != null) {
    //     this.enabledOrganizationApp = response.data?.apps?.[32] || false;
    //   }
    // },
    accessToV1() {
      // const response = await this.$store.$service[Service.BackendClient].post(
      //   "accessToV1",
      //   {
      //     communitySlug: this.currentCollective.slug,
      //   }
      // );
      window.open(
        //`${response.data.URL}?key=${Vue.$cookies.get("accessToken")}`,
        `https://app.copernic.tech/workspace/${this.currentCollective.slug}?key=${Vue.$cookies.get("accessToken")}`,
        "_blank"
      );
    },
    async accessHelpCenter() {
      const main = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL?.replace(
        "{subdomain}",
        "support"
      )?.replace("{slug}", "help-center");
      window.open(`${main}`, "_blank");
    },
    flagImage(activeLocale) {
      // console.log('activeLocale', activeLocale);
      return activeLocale ? activeLocale.img : null;
    },
    toAbsoluteURL,
    /*     handleCreateProfile() {

      this.isOpenedStaffModal = false;
      return this.$router.push(
        {
          name: 'member-details',
          params: { username: this.loggedUser.username, communityId: this.$route.params.communityId },
        },
      );
    }, */
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include";
.sub-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.etiquetaSTG {
  border: 5px solid #ffffff;
  border-radius: 27px;
  color: white;
  background-color: orange;
  font-weight: bold;
  font-size: 1.1em;
  padding: 5px 10px 5px 10px;
}
.etiquetaLocal {
  border: 5px solid #ffffff;
  border-radius: 27px;
  color: white;
  background-color: purple;
  font-weight: bold;
  font-size: 1.1em;
  padding: 5px 10px 5px 10px;
}
.avatar {
  width: 40px;
  height: 40px;
  object-fit: contain;
  border-radius: 50%;
  background: white;
}
.menu {
  display: block;
}
a.disabled {
  pointer-events: none;
}

// .icon-nectios {
//   color: #6e6b7b;
// }
//   .icon-nectios {
//   filter: grayscale(100%);
//   color: $primary !important;
// }

// .icon-text:hover {
//       color: $primary !important;
// }

@media screen {
  @media (max-width: 550px) {
    .etiquetaSTG {
      display: none;
    }
    .etiquetaLocal {
      display: none;
    }
  }
}
@include media-breakpoint-up(lg) {
  .settings {
    min-width: 275px !important;
  }
}
@include media-breakpoint-up(xl) {
  .menu {
    display: none !important;
  }
}
</style>
